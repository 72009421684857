<template>
  <div class="ai_videobox">
    <div class="ai_videobox_left">
      <div class="add_video_button">
        <el-upload
          action=""
          :auto-upload ='false'
          accept=".mp4"
          :on-change="onChange"
          :limit="2"
          class="add_video_button_bg"
          :file-list="fileList"
          >
          <el-button size="small" type="primary"></el-button>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
      <div class="video"></div>
    </div>
    <div class="ai_videobox_right">
      <div class="title">
        配置AI算法
      </div>
      <div class="ai_icon">
        <div class="ai-swiper">
          <div
            class="swiper-slide cpt-device-ai"
            v-for="item in dataSource"
            :key="item.id"
          >
            <el-checkbox
              class="device-ai-checkbox"
              v-model="item.checked"
            ></el-checkbox>
            <span
              class="device-ai-icon iconfont"
              :class="item.icon"
              alt="ai"
            ></span>
            <div class="device-ai-cover">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import API from "@/api";

export default {
    props: {
    dataSource: {
      type: Array,
      default: () => [],
    },

  },
    data(){
      return{
        dialogImageUrl: '',
        dialogVisible: false,
        url:'',
        fileList: [],
      }
    },
  computed: {

  },
  methods: {
    onChange(file, fileList1) {
            if (fileList1&&fileList1.length>=2) {
                fileList1.shift();
            }
            this.fileList = fileList1;
        },

    async sub() { // 提交文件
            if(this.fileList.length>0) {
                let res = null
                let formData = new FormData()
                formData.append("uploadFile", this.fileList[0].raw)
                try {
                    res = await API.TASK.Upvideo(formData);
                } catch (error) {
                    console.log(error);
                }
                
            } else {
                this.$message.warning('请先选择文件!');
            }
        }
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  .ai_videobox{
    display: flex;
    .ai_videobox_left{
      width: 524px;
      height: 274px;
      margin-right: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, rgba(50, 117, 222, 0.2) 0%, rgba(67, 222, 255, 0.4) 52%, rgba(50, 117, 222, 0.2) 100%);
      border: 1px solid rgba(132, 178, 255, 0.4);
      .add_video_button{
        .add_video_button_bg{
          margin: 0 auto;
        }
      }
    }
    .ai_videobox_right{
      display: flex;
      flex: 1;
      .title{
        width: 22px;
        height: 110px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #CCE8FF;
        line-height: 22px;
        margin-right: 14px;
      }
      .ai_icon{
        height: 274px;
        overflow:auto;
      }
    }
  }
::v-deep .el-button--small {
            width: 128px;
            height: 128px;
            padding: 0px;
            border: 1px dashed #ffff;
            border-radius: 0px;
            background-color: transparent;
            background-image: url(~@/assets/platform/aivideo/addicon.png);
            background-repeat: no-repeat;
            background-position: center;
    }

  ::v-deep {
    .el-checkbox {
      width: 14px;
      height: 14px;
      box-sizing: border-box;
      .el-checkbox__input {
        box-sizing: border-box;
        .el-checkbox__inner {
          background: transparent;
          border: 1px solid #cce8ff;
          border-radius: 14px;
          &::after {
            width: 6px;
            height: 6px;
            border-radius: 6px;
            position: absolute;
            left: 50%;
            top: 50%;
            box-sizing: border-box;
            overflow: hidden;
          }
        }
      }
      .el-checkbox__label {
        font-size: 14px;
        color: #cce8ff;
      }
      &.is-checked {
        .el-checkbox__input {
          box-sizing: border-box;
          .el-checkbox__inner {
            background: transparent;
            &::after {
              background: #cce8ff;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .ai-swiper {
    display: flex;
    flex-wrap: wrap;
    .cpt-device-ai {
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      width: 92px;
      height: 84px;
      margin-left: 16px;
      margin-bottom: 10px;
      box-sizing: border-box;
      background-image: linear-gradient(
        180deg,
        rgba(50, 117, 222, 0.2) 0%,
        rgba(67, 222, 255, 0.4) 52%,
        rgba(50, 117, 222, 0.2) 100%
      );
      border: 1px solid rgba(132, 178, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .device-ai-checkbox {
        position: absolute;
        left: 4px;
        top: 4px;
      }
      .device-ai-icon {
        /* width: 80px;
        height: 80px; */
        font-size: 36px;
        color: #cce8ff;
      }
      .device-ai-cover {
        height: 24px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 12px;
        color: #9fc4ff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

</style>