<template>
  <div class="page-task-achievement">
    <List :onEdit="() => (visible.edit = true)" :edit="(value, type) =>{editshow(value, type)}"/>
    <el-dialog
      :title="'处理视频'"
      :visible.sync="visible.edit"
      width="936px"
      top="330px"
      :close-on-click-modal="false"
    >

    <Aivido :dataSource="ai.dataSource" ref="child" />

    <div style="display: flex; justify-content: center; margin-top: 28px">
        <!-- <div @click="handle_choose_ai(0)" class="linear-btn" style="margin-right: 20px">算法处理</div>
        <div @click="handle_choose_ai(1)" class="linear-btn">保存视频</div>
        <div @click="handle_choose_ai(1)" class="linear-btn">放弃视频</div> -->
        <div class="linear-btn" style="margin-right: 20px">算法处理</div>
        <div class="linear-btn" style="margin-right: 20px" @click="save">保存视频</div>
        <div class="linear-btn">放弃视频</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "./list";
import API from "@/api"
import Aivido from "@/components/device/ai-video";
export default {
  data(){
    return{
      visible: {
        edit: false,
        notice: true,
        again: true,
        dataSource:[],
      },
      ai: {
            dataSource: [],
            data: null,
      },
    }
  },
  components: { List , Aivido},
  methods:{
    async async_get_ai_list(data) {
      try {
        let res = await API.AI.List();
        res = res.data;
        this.ai.dataSource = res.map((item) => ({
          title: item.value,
          id: item.key,
          icon: item.path,
          checked: false,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    save(){
      this.$refs.child.sub()
    }
  },
  mounted(){
    this.async_get_ai_list()
  }
};
</script>

<style lang="scss" scoped>
.page-task-achievement {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
}
::v-deep .el-dialog__body{
      padding: 28px 10px;
}
</style>