<template>
  <div class="cpt-task-achievement-search">
    <div style="display: flex; align-items: center">
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
      <el-form-item label="">
          <!-- <div class="linear-btn" @click="$emit('on-edit')">
            处理视频
          </div> -->
        </el-form-item>
        <el-form-item label="视频名称:">
          <el-input
            v-model="search_form.name"
            placeholder="请输入视频名称"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="资料类型:">
          <el-select v-model="search_form.typeId" size="mini" clearable>
            <el-option
              v-for="item in drd.type"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="">
          <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item> -->
        <el-form-item label="">
          <div  class="linear-btn" @click="$emit('on-search', search_form)">
            搜索
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search_form: {
        name: null,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input{
  width: 178px;
  .el-input__inner{
    width: 100% !important;
  }
}
.cpt-task-achievement-search {
  width: 100%;
  margin-top: 5px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

</style>