<template>
  <Box>
    <div class="page-service-aiVideo">
      <Search @on-search="handle_search" @on-edit="onEdit" />
      <div class="achi-list-wrap">
        <div class="achi-list" >
          <div class="achi-item" v-for="item in dataSource" :key="item.id" :id="item.id" >
            <el-popover
              popper-class="achi-action-popover"
              placement="right"
              width="400"
              trigger="click"
              @show="show_boder(item.id)"
              @hide="hide(item.id)"
            >
              <div class="action-list">
                <div @click="handle_review(item)" class="action-item">查看</div>
                <div @click="handle_download(item)" class="action-item">
                  下载
                </div>
                <div @click="handle_before_rename(item)" class="action-item">
                  重命名
                </div>
              </div>
              <div class="achi-item" slot="reference">
                <div class="achi-icon-box">
                  <img
                    class="achi-icon"
                    :src="require(`@/assets/platform/achi-0${item.typeId}.png`)"
                    alt="achi-type"
                  />
                </div>
                <div :title="item.name" class="achi-text-box">
                  <input
                    :ref="`achi-${item.id}`"
                    type="text"
                    v-model="item.name"
                    @keydown.enter="handle_rename(item)"
                  />
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="table-pagination">
        <Pagination
          @size-change="handle_size_change"
          @page-change="handle_page_change"
          :current-page="pagination.page || 1"
          :page-sizes="pagination.pageSizes || [10, 20, 50, 100]"
          :page-size="pagination.pageSize || 10"
          :total="pagination.total || 0"

        />
      </div>
    </div>

    <el-dialog
      title="查看视频"
      :visible.sync="review.video.visible"
      width="500px"
    >
      <video
        style="width: 100%; height: 230px"
        autoplay="autoplay"
        controls
        ref="video"
        v-if="review.video.url"
        :src="review.video.url"
      ></video>
    </el-dialog>
  </Box>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

import Box from "@/components/platform/common/box";
import Pagination from "@/components/common/pagination";
import Search from "./search";
import { props } from "./props";
import API from "@/api";
export default {
  components: { Box, Pagination, Search },
  props: _.pick(props, ["onEdit", "edit"]),
  data() {
    return {
      search: {},
      dataSource: [],
      dataSources: [],
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },
      review: {
        image: {
          list: [],
          options: {
            url: "data-source",
          },
        },
        video: {
          visible: false,
          url: null,
        },
      },
    };
  },
  watch: {
    "review.video.visible": {
      handler(value) {
        if (!value) {
          this.$refs.video.currentTime = 0;
          this.$refs.video.pause()
        }else {
          this.$refs.video.play()
        }
      }
    }
  },
  methods: {
    async async_get_list() {
      const { page, pageSize } = this.pagination;
      const { data } = await API.TASK.GetAchievementList({
        ...this.search,
        typeId: 2,
        pageNum: page,
        pageSize: pageSize,
      });
      this.dataSource = data.data || [];
      this.pagination.page = data.pageNo;
      this.pagination.total = data.totalCount;
      
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.async_get_list();
    },
    handle_page_change(page) {
      this.pagination.page = page;
      this.async_get_list();
    },

    handle_review(data) {
      if (data.typeId == 1) {
        this.review.image.list.push(
          // require(`@/assets/platform/achi-0${data.typeId}.png`)
          data.storage
        );
        this.$viewerApi({
          images: this.review.image.list,
        });
      } else if (data.typeId == 2) {
        this.review.video.visible = true;
        this.review.video.url = data.storage;
      } else if (data.typeId == 4) {
        window.open(data.storage, "_blank");
      }
    },

    async handle_download(data) {
      const res = await API.TASK.download({
        id: data.key,
      });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      if (data.typeId === 1) {
        a.download = `${data.name}.png`;
      } else {
        const b = document.createElement("a");
        b.href = data.url;
        b.download = `${data.name}.mp4`;
        b.click();
        b.remove();
        return

      }
      
      a.click();
    },



    handle_before_rename(data) {
      this.$refs[`achi-${data.id}`][0].focus();
    },
    async handle_rename(data) {
      let res = await API.TASK.taskDataUpdate({
        id: data.id,
        name: data.name
      })
      if (res.status === 1) {
        this.$el_message('修改成功');
        this.async_get_list();
      }
    },
    show_boder(a){
      const elment = document.getElementById(a)
      elment.style.border="1px solid #488CFF"
    },
    hide(a){
      const elments = document.getElementById(a)
      elments.style.border="1px solid transparent"
    }
  },
  mounted() {
    this.async_get_list();
  },
};
</script>

<style lang="scss">
.achi-action-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;
  .popper__arrow {
    display: none;
  }
  .action-list {
    .action-item {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        color: #2cacff;
      }
    }
  }
  width: 98px !important;
}
</style>

<style lang="scss" scoped>
.page-service-aiVideo {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .achi-list-wrap {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .achi-list {
      display: flex;
      flex-wrap: wrap;
      margin: 39px -73px -32px 0px;
      .achi-item {
        width: 180px;
        height: 182px;
        margin: 0 73px 22px 0;
        border: 1px solid transparent;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        .achi-icon-box {
          width: 156px;
          height: 115px;
          position: relative;
          .achi-icon {
            width: 100%;
            height: 100%;
          }
        }
        .achi-text-box {
          input {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #a8bdd4;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            padding: 0;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .table-pagination {
    flex-shrink: 0;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

</style>